import {IPlatformFormTemplate} from '../interface/platform/IPlatformFormTemplate';
import {PlatformType} from '../enum/PlatformType';
import {FormArray, FormControl, UntypedFormControl} from '@angular/forms';
import {IPlatform} from '../interface/platform/IPlatform';
import {ExecutionPointType} from '../enum/ExecutionPointType';
import {IExecutionPoint} from '../interface/execution-point/IExecutionPoint';
import {uuidValidator} from './uuid.validator';
import _ from 'lodash';

export const UTM_CAMPAIGN_PREFILL = 'boombit_crosspromo';

export function getPlatformTemplateByType(platformType: PlatformType, boombitId: string,
                                          initValue?: IPlatform): IPlatformFormTemplate {
  let platform: IPlatformFormTemplate = {};
  switch (platformType) {
    case PlatformType.ANDROID:
      platform = initValue ? getAndroidPlatformTemplate(initValue) : getNewAndroidPlatformTemplate();
      break;
    case PlatformType.IOS:
      platform = initValue ? getIOSPlatformTemplate(initValue) : getNewIOSPlatformTemplate();
      break;
    case PlatformType.WEB:
      platform = initValue ? getWebPlatformTemplate(initValue) : getNewWebPlatformTemplate(boombitId);
      break;
    case PlatformType.SAMSUNG:
      platform = initValue ? getSamsungPlatformTemplate(initValue) : getNewSamsungPlatformTemplate();
      break;
    case PlatformType.HUAWEI:
      platform = initValue ? getHuaweiPlatformTemplate(initValue) : getNewHuaweiPlatformTemplate();
      break;
    case PlatformType.AMAZON:
      platform = initValue ? getAmazonPlatformTemplate(initValue) : getNewAmazonPlatformTemplate();
  }
  return platform;
}

function getNewIOSPlatformTemplate(): IPlatformFormTemplate {
  return {
    type: PlatformType.IOS,
    useDefaultProductPage: true,
    productPageIds: new FormArray<FormControl<string>>([new FormControl<string>('', [uuidValidator])]),
    useOptimizedQueue: false
  };
}

function getNewAndroidPlatformTemplate(): IPlatformFormTemplate {
  return {
    type: PlatformType.ANDROID,
    utmCampaign: UTM_CAMPAIGN_PREFILL,
    useOptimizedQueue: false
  };
}

function getNewWebPlatformTemplate(boombitId: string): IPlatformFormTemplate {
  return {
    type: PlatformType.WEB,
    useOptimizedQueue: false,
    platformId: boombitId ? boombitId + '.web': ''
  };
}

function getNewSamsungPlatformTemplate(): IPlatformFormTemplate {
  return {
    type: PlatformType.SAMSUNG,
    useOptimizedQueue: false
  };
}

function getNewHuaweiPlatformTemplate(): IPlatformFormTemplate {
  return {
    type: PlatformType.HUAWEI,
    useOptimizedQueue: false
  };
}

function getNewAmazonPlatformTemplate(): IPlatformFormTemplate {
  return {
    type: PlatformType.AMAZON,
    useOptimizedQueue: false
  };
}

function getWebPlatformTemplate(initValue: IPlatform): IPlatformFormTemplate {
  return {
    id: initValue.id,
    type: PlatformType.WEB,
    platformId: initValue.platformId,
    defaultURL: getUrl(initValue),
    executionPointsIds: [{type: ExecutionPointType.URL, id: initValue.executionPoints[0]?.id}],
    useOptimizedQueue: initValue.useOptimizedQueue,
    externalTracking: _.cloneDeep(initValue.externalTrackings),
    enabled: !initValue.disabled,
    whitelistEnabled: !initValue.ignoreWhitelist
  };
}


function getAndroidPlatformTemplate(initValue: IPlatform): IPlatformFormTemplate {
  const executionPoint = getExecutionPointByType(initValue, ExecutionPointType.GOOGLE_PLAY);
  return {
    id: initValue.id,
    type: PlatformType.ANDROID,
    platformId: initValue.platformId,
    defaultURL: getUrl(initValue),
    utmCampaign: executionPoint?.utmCampaign ? executionPoint.utmCampaign : '',
    executionPointsIds: [{type: ExecutionPointType.GOOGLE_PLAY, id: initValue.executionPoints[0]?.id}],
    useOptimizedQueue: initValue.useOptimizedQueue,
    externalTracking: _.cloneDeep(initValue.externalTrackings),
    enabled: !initValue.disabled,
    whitelistEnabled: !initValue.ignoreWhitelist
  };
}

function getSamsungPlatformTemplate(initValue: IPlatform): IPlatformFormTemplate {
  return {
    id: initValue.id,
    type: PlatformType.SAMSUNG,
    platformId: initValue.platformId,
    defaultURL: getUrl(initValue),
    executionPointsIds: [{type: ExecutionPointType.SAMSUNG, id: initValue.executionPoints[0]?.id}],
    useOptimizedQueue: initValue.useOptimizedQueue,
    externalTracking: _.cloneDeep(initValue.externalTrackings),
    enabled: !initValue.disabled,
    whitelistEnabled: !initValue.ignoreWhitelist
  };
}

function getHuaweiPlatformTemplate(initValue: IPlatform): IPlatformFormTemplate {
  return {
    id: initValue.id,
    type: PlatformType.HUAWEI,
    platformId: initValue.platformId,
    defaultURL: getUrl(initValue),
    executionPointsIds: [{type: ExecutionPointType.HUAWEI, id: initValue.executionPoints[0]?.id}],
    useOptimizedQueue: initValue.useOptimizedQueue,
    externalTracking: _.cloneDeep(initValue.externalTrackings),
    enabled: !initValue.disabled,
    whitelistEnabled: !initValue.ignoreWhitelist
  };
}

function getAmazonPlatformTemplate(initValue: IPlatform): IPlatformFormTemplate {
  return {
    id: initValue.id,
    type: PlatformType.AMAZON,
    platformId: initValue.platformId,
    defaultURL: getUrl(initValue),
    executionPointsIds: [{type: ExecutionPointType.AMAZON, id: initValue.executionPoints[0]?.id}],
    useOptimizedQueue: initValue.useOptimizedQueue,
    externalTracking: _.cloneDeep(initValue.externalTrackings),
    enabled: !initValue.disabled,
    whitelistEnabled: !initValue.ignoreWhitelist
  };
}

function getIOSPlatformTemplate(initValue: IPlatform): IPlatformFormTemplate {
  const executionPoint = getExecutionPointByType(initValue, ExecutionPointType.APP_STORE);
  return {
    id: initValue.id,
    type: PlatformType.IOS,
    platformId: initValue.platformId,
    defaultURL: getUrl(initValue),
    // below checkbox should be prefilled unless app store is enabled
    useDefaultProductPage: !!executionPoint ? executionPoint?.useDefaultProductPage === true : true,
    productPageIds: getProductPageIdFormArray(executionPoint),
    executionPointsIds: [{type: ExecutionPointType.APP_STORE, id: initValue.executionPoints[0]?.id}],
    useOptimizedQueue: initValue.useOptimizedQueue,
    externalTracking: _.cloneDeep(initValue.externalTrackings),
    enabled: !initValue.disabled,
    whitelistEnabled: !initValue.ignoreWhitelist
  };
}

function getExecutionPointByType(platform: IPlatform, type: ExecutionPointType): IExecutionPoint {
  return platform.executionPoints?.find(point => point.type === type);
}

export function hasUrl(platform: IPlatform): boolean {
  const executionPoint = platform.executionPoints[0];
  return !!executionPoint?.defaultURL;
}

export function getUrl(platform: IPlatform): string {
  if (hasUrl(platform)) {
    return platform.executionPoints[0].defaultURL;
  } else {
    return '';
  }
}

function getProductPageIdFormArray(appStoreExecutionPoint: IExecutionPoint): FormArray<FormControl<string>> {
  if (appStoreExecutionPoint?.productPageIds?.length > 0) {
    const controls: FormControl<string>[] = appStoreExecutionPoint.productPageIds
      .map(productPageId => new UntypedFormControl(productPageId, [uuidValidator]));
    return new FormArray<FormControl<string>>(controls);
  } else {
    return new FormArray<FormControl<string>>([new UntypedFormControl('', [uuidValidator])]);
  }
}

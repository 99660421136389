import {IPlatformForm} from '../interface/platform/IPlatformForm';
import {IPlatformRaw} from '../interface/platform/IPlatformRaw';
import {PlatformType} from '../enum/PlatformType';
import {ExecutionPointType} from '../enum/ExecutionPointType';
import {IExecutionPointRaw} from '../interface/execution-point/IExecutionPointRaw';
import {IExternalTrackingRaw} from '../interface/IExternalTrackingRaw';

export function getUpdatedPlatformRaw(platform: IPlatformForm): IPlatformRaw {
  const platformRaw: IPlatformRaw = getPlatformRaw(platform);
  addPlatformId(platformRaw, platform);
  addExecutionPointsIds(platformRaw, platform);
  return platformRaw;
}

function addExecutionPointsIds(platformRaw: IPlatformRaw, platform: IPlatformForm): void {
  platformRaw.executionPoints.forEach((executionPoint: IExecutionPointRaw) => {
    const executionPointId = platform.executionPointsIds.find(el => el.type === executionPoint.type);
    if (executionPointId) {
      executionPoint.id = executionPointId.id;
    }
  });
}

function addPlatformId(platformRaw: IPlatformRaw, platform: IPlatformForm): void {
  if (platform.id) {
    platformRaw.id = platform.id;
  }
}

export function getPlatformRaw(platform: IPlatformForm): IPlatformRaw {
  const platformType: PlatformType = platform.type.name as PlatformType;
  switch (platformType) {
    case PlatformType.WEB:
      return getWebPlatformRaw(platform);
    case PlatformType.IOS:
      return getIOSPlatformRaw(platform);
    case PlatformType.ANDROID:
      return getAndroidPlatformRaw(platform);
    case PlatformType.SAMSUNG:
      return getSamsungPlatformRaw(platform);
    case PlatformType.HUAWEI:
      return getHuaweiPlatformRaw(platform);
    case PlatformType.AMAZON:
      return getAmazonPlatformRaw(platform);
  }
}

export function getWebPlatformRaw(platform: IPlatformForm): IPlatformRaw {
  return {
    type: PlatformType.WEB,
    platformId: platform.platformId,
    executionPoints: [{type: ExecutionPointType.URL, defaultURL: platform.defaultURL}],
    useOptimizedQueue: platform.useOptimizedQueue,
    externalTrackings: getExternalTracking(platform),
    disabled: getDisabled(platform),
    ignoreWhitelist: getIgnoreWhitelist(platform)
  };
}

export function getIOSPlatformRaw(platform: IPlatformForm): IPlatformRaw {
  const executionPoint: IExecutionPointRaw = {
    type: ExecutionPointType.APP_STORE,
    productPageIds: platform.productPageIds.filter(el => !!el?.trim()) || [],
    useDefaultProductPage: !!platform.useDefaultProductPage,
    defaultURL: platform.defaultURL || null
  };
  const platformId = platform.platformId;
  return {
    type: PlatformType.IOS, platformId,
    executionPoints: [executionPoint],
    useOptimizedQueue: platform.useOptimizedQueue,
    externalTrackings: getExternalTracking(platform),
    disabled: getDisabled(platform),
    ignoreWhitelist: getIgnoreWhitelist(platform)
  };
}

export function getAndroidPlatformRaw(platform: IPlatformForm): IPlatformRaw {
  const executionPoint: IExecutionPointRaw = {
    type: ExecutionPointType.GOOGLE_PLAY,
    defaultURL: platform.defaultURL || null,
    utmCampaign: platform.utmCampaign.trim()
  };
  const platformId = platform.platformId;
  return {
    type: PlatformType.ANDROID,
    platformId,
    executionPoints: [executionPoint],
    useOptimizedQueue: platform.useOptimizedQueue,
    externalTrackings: getExternalTracking(platform),
    disabled: getDisabled(platform),
    ignoreWhitelist: getIgnoreWhitelist(platform)
  };
}

export function getHuaweiPlatformRaw(platform: IPlatformForm): IPlatformRaw {
  const executionPoint: IExecutionPointRaw = {
    type: ExecutionPointType.HUAWEI,
    defaultURL: platform.defaultURL || null
  };
  const platformId = platform.platformId;
  return {
    type: PlatformType.HUAWEI,
    platformId,
    executionPoints: [executionPoint],
    useOptimizedQueue: platform.useOptimizedQueue,
    externalTrackings: getExternalTracking(platform),
    disabled: getDisabled(platform),
    ignoreWhitelist: getIgnoreWhitelist(platform)
  };
}

export function getAmazonPlatformRaw(platform: IPlatformForm): IPlatformRaw {
  const executionPoint: IExecutionPointRaw = {
    type: ExecutionPointType.AMAZON,
    defaultURL: platform.defaultURL || null
  };
  const platformId = platform.platformId;
  return {
    type: PlatformType.AMAZON,
    platformId,
    executionPoints: [executionPoint],
    useOptimizedQueue: platform.useOptimizedQueue,
    externalTrackings: getExternalTracking(platform),
    disabled: getDisabled(platform),
    ignoreWhitelist: getIgnoreWhitelist(platform)
  };
}

export function getSamsungPlatformRaw(platform: IPlatformForm): IPlatformRaw {
  const executionPoint: IExecutionPointRaw = {
    type: ExecutionPointType.SAMSUNG,
    defaultURL: platform.defaultURL || null
  };
  const platformId = platform.platformId;
  return {
    type: PlatformType.SAMSUNG,
    platformId,
    executionPoints: [executionPoint],
    useOptimizedQueue: platform.useOptimizedQueue,
    externalTrackings: getExternalTracking(platform),
    disabled: getDisabled(platform),
    ignoreWhitelist: getIgnoreWhitelist(platform)
  };
}


function getExternalTracking(platform: IPlatformForm): IExternalTrackingRaw[] {
  const externalTracking: IExternalTrackingRaw[] = [].concat(...platform.externalTracking.map(group => group.trackingElement))
    .map((trackingEl: IExternalTrackingRaw) => {
      if (!trackingEl.id) {
        delete trackingEl.id;
      }
      return trackingEl;
    });
  return externalTracking.filter(el => !!el.url.trim());
}

function getDisabled(platform: IPlatformForm): boolean {
  return !platform.enabled;
}

function getIgnoreWhitelist(platform: IPlatformForm): boolean {
  return !platform.whitelistEnabled;
}
